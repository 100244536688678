import React from 'react';
import { Slide } from 'react-slideshow-image';

import Guys from './img/guys_ski-min.jpg';
import Kids from './img/kids_ski-min.jpg';
import ManWoman from './img/man_woman_ski-min.jpeg';
import Man from './img/home_header-min.jpg';

//Class that holds slideshow
export default class Home extends React.Component {
    render() {
        const slideImages = [
            Guys,
            Kids,
            ManWoman,
            Man
        ];

        const properties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true
        };

        return(
            <div id="home" className="home_slide_container">
                <Slide {...properties}>
                    {slideImages.map((value, index) =>
                        <div key={index} className="each_slide">
                            <div style={{'backgroundImage': `url(${value})`}}>
                            </div>
                        </div>
                    )}
                </Slide>
            </div>
        )
    }
}