import React from 'react';

import bootfittingData from '../data/bootfitting';

import Typography from '@material-ui/core/Typography';

export default class Bootfitting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: bootfittingData.videos
        }
    }

    render() {
        const bootfittingData = this.state.data;

        return (
            <div id="bootfitting" className="section">
                <h1 className="section-title">Bootfitting</h1>
                <Typography className="bootfitting_intro_title" variant="h6">
                    Skischoenen op maat en bootfitting:
                </Typography>
                <Typography className="bootfitting_intro_txt">
                    Iedereen wil natuurlijk een aansluitend paar skischoenen met een hoog draagcomfort. Wij bieden de
                    service om skischoenen op maat te maken en of pijnvrij. Het pasproces begint natuurlijk altijd met
                    een uitgebreide voetmeting. Hierbij kunnen wij gebruik maken van een 3D scan waarbij alle waardes
                    van de voet in kaart kunnen worden gebracht. Hiernaast proberen wij zo goed mogelijk naar uw lichaam
                    en stand te kijken om zo een juiste beslissing te maken.

                    Wij streven altijd naar een schoen die in de basis het beste bij een klant past. Soms zijn er dan
                    nog aanpassingen nodig om de schoen perfect te maken. Dit kunnen kleine aanpassingen zijn maar soms
                    moet het iets rigoureuzer aangepakt worden. Hiervoor hebben wij meerdere methodes in huis zoals:
                </Typography>
                {bootfittingData.map((bootfittingItem, index) =>
                    <div className="bootfitting_card" key={index}>
                        <iframe className={bootfittingItem.vidUrl == null ? 'bootfitting_vid_noshow' : 'bootfitting_vid'} title="bootfitting-vid" width="100%" height="450px" src={bootfittingItem.vidUrl} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                        <Typography className="bootfitting_title" variant="h6">
                            {bootfittingItem.title}
                        </Typography>
                        <Typography>
                            {bootfittingItem.text}
                        </Typography>
                    </div>
                )}
            </div>
        )
    }
}