import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import serviceData from '../data/services';

export default class Services extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: serviceData.servicesItems
        }
    }

    render() {
        const serviceData = this.state.data;
        return (
            <div id="services" className="section">
                <h1 className="section-title">Vakmanschap en services</h1>
                {serviceData.map((serviceItem, index) =>
                    <Card classes={{root: 'service_card'}} key={index}>
                        <CardContent>
                            <Icon className="service_icon" fontSize="large">{serviceItem.icon}</Icon>
                            <Typography variant="h5" component="h2">
                                {serviceItem.text}
                            </Typography>
                        </CardContent>
                    </Card>
                )}

                <p className="service_extra_info">
                    *Wanneer de door u bij ons gekochte ski's of skischoenen toch niet bevallen, brengen wij alleen de huurprijs in rekening en ruilen het product om voor iets anders.
                </p>
            </div>
        )
    }
}