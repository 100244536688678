import React from "react";
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

export default class Contact extends React.Component {
    render() {
        return (
            <div id="contact" className="section">
                <h1 className="section-title">Contact</h1>
                <div className="info_content">
                    <div className="info_icon_wrapper">
                        <Icon className="info_icon" fontSize="large">info</Icon>
                    </div>
                    <div className="info_text">
                        <Typography>
                            Onze winkel bevindt zich op de hoek van de Schiedamsedijk en het Vasteland, aan de voet van de Erasmusbrug. De beste mogelijkheid om te parkeren is in de Parkeergarage Erasmusbrug aan het Willemsplein 1 (3016 DN Rotterdam).
                            <br/>
                            <br/>
                            Met de metro kunt u ons bereiken met lijn D (Halte: Leuvehaven), wij zijn ongeveer 5 minuten lopen van Metro halte Beurs verwijderd (Richting Erasmusbrug). Komt u met de tram dan kunt u ons bereiken via lijnen 8, 20 (Halte Vasteland) en 23, 25 (Halte Schiedamsedijk).
                        </Typography>
                    </div>
                </div>

                <iframe className="google_maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1554.044448464038!2d4.481382977170621!3d51.912087066735246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c433612fda25f3%3A0xec3b6fa892d34a16!2sNorbert%20Maier%20Sport!5e0!3m2!1snl!2snl!4v1575049767026!5m2!1snl!2snl"
                        frameBorder="0"
                        allowFullScreen=""
                        title="googlemaps"
                >

                </iframe>
            </div>
        )
    }
}